import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import CustomLayout from '../components/layouts/CustomPageLayout'

class CustomPageTemplate extends React.Component {
    render() {
        const site = get(this.props, 'data.site.siteMetadata')
        const page = get(this.props, 'data.contentfulPageCustom')
        let sitemap = get(this.props, 'data.allContentfulWidgetSitemap.edges', [])
        sitemap = sitemap.map(link => link && link.node)
        return (
            <CustomLayout site={site} page={page} sitemap={sitemap} />
        )
    }
}

export default CustomPageTemplate

export const pageQuery = graphql`
query PageCustomBySlug($slug: String!, $locale: String!) {
    site {
        siteMetadata {
            title
            description
            siteUrl
            twitterHandle
        }
    }
    contentfulPageCustom(slug: { eq: $slug },node_locale: { eq: $locale }) {
        __typename
        title
        slug
        userSection
        showSocialShare
        metaDescription {
            metaDescription
        }
        html {
            file {
                url
            }
        }
        css {
            file {
                url
            }
        }
        scripts {
            file {
                url
            }
        }
    }
    allContentfulWidgetSitemap(filter:{title: {ne: "Don't delete - sitemap"}}) {
        totalCount
        edges {
            node {
                contentful_id
                userSection
                title
                slug
                links {
                    __typename
                    ... on ContentfulPageCustom {
                        slug
                        title
                        contentful_id
                        userSection
                    }
                    ... on ContentfulPageDirectoryOfServices {
                        id
                        title
                    }
                    ... on ContentfulPageDonation {
                        slug
                        title
                        contentful_id
                    }
                    ... on ContentfulPageGeneric {
                        slug
                        title
                        userSection
                    }
                    ... on ContentfulPageGroup {
                        slug
                        title
                        userSection
                    }
                    ... on ContentfulPageGuideline {
                        title
                        slug
                    }
                    ... on ContentfulPageWidgets {
                        title
                        slug
                        userSection
                    }
                }
            }
        }
    }
}
`
